<template>
  <SubNav class="subnav-position" :selected="'updates'" :id="id" />
  <main>
    <div v-if="offeringUpdates" class="subnav-detail">
      <div class="float-right">
        <router-link
          class="btn btn-primary"
          :to="{ name: 'CreateUpdate', params: { id: id } }"
        >
          Create Offering Update
        </router-link>
      </div>
      <div class="error">{{ error }}</div>
      <div v-for="offeringUpdate in offeringUpdates" :key="offeringUpdate.id">
        <router-link
          :to="{
            name: 'EditUpdate',
            params: { id: id, updateId: offeringUpdate.id }
          }"
        >
          <div class="row single">
            <div class="col-4">
              <div class="subnav-thumbnail">
                <img :src="offeringUpdate.mainImageURL" />
              </div>
            </div>
            <div class="col-3">
              <div class="subject">
                {{ offeringUpdate.title }}
              </div>
              <div class="created-at">
                <p>{{ offeringUpdate.date }}</p>
              </div>
            </div>
            <div class="col message">
              <p v-html="offeringUpdate.description"></p>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </main>
  <Footer v-if="offeringUpdates" />
</template>

<script>
import SubNav from "@/components/navigation/SubNav.vue";
import getCollection from "@/composables/getCollection";
import Footer from "@/components/navigation/Footer.vue";

export default {
  props: ["id"],
  components: { SubNav, Footer },
  setup(props) {
    const { error, documents: offeringUpdates } = getCollection(
      "offeringUpdates",
      [["offeringId", "==", props.id]],
      ["createdAt", "desc"]
    );

    return { error, offeringUpdates };
  }
};
</script>

<style>
</style>